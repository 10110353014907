import React from 'react';
import './App.css';

const ComingSoon = () => {
    return (
        <div className="coming-soon-container">
            <h1 className="coming-soon-title">Coming Soon</h1>
            <p className="coming-soon-description">We're working hard to bring you an amazing image gallery. Stay tuned!</p>
            <div className="coming-soon-image">
                <img src={`${process.env.PUBLIC_URL}/coming_soon_image.png`} alt="Coming soon" />
            </div>
        </div>
    );
};

export default ComingSoon;
